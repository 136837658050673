<template>
	<div class="guide">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>
		<GuideC></GuideC>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import GuideC from '@/components/Guide.vue'
import TopCover from '@/components/TopCover.vue'

export default {
    metaInfo: {
        title: '导航 - KASUIEの次元',
        meta: [
    	    {
    		    name: 'description',
                content: '导航',
    	    },
            {
                name: 'keywords',
                content: '导航，资源导航，导航网站，资源整理'
            }
      ]
    },
  name: 'Guide',
  components: {
	  GuideC,
	  TopCover,
	  Nav
  },
  data() {
  	return {
		pageMsg:{
			title: '导航',
			imgUrl: 'https://i.loli.net/2021/07/30/6LrE31zxvGUiXat.jpg',
			article: ''
		},
	}
  },
  created() {
  	document.title = 'KASUIEの导航'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style>
</style>
