<template>
	<div class="guide-content" v-loading='loading'
	 element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
		<ul id="ul_nav">
			<li v-for="(navItem,index) in navItems" class="navItem" v-bind:class="'navItem'+navItem.typeId" :key="'navItem'+index">
                <div><span v-text=navItem.type class="type"></span><ul><span class="tag_icon"><img src="/img/kasuie/tag.png" alt="tag"></span><li class="tag" :class="tagFlag[1]==''||tagFlag[1]==null?'tagAll':''" @click="tags(navItem.typeId,$event)">全部</li><li v-for="(tag,index) in navItem.tags" :key="'tag'+index" v-text=tag class="tag" :class="tagFlag[0]==navItem.typeId&tagFlag[1]==tag?'tagShow':''" @click="tags(navItem.typeId,$event)"></li></ul></div>
                <ul :id="'id'+navItem.typeId" class="ul_site">
                    <li v-for="(site,index) in navItem.sitesItems" class="site" :key="'site'+index"><a v-bind:href="site.url" v-text="site.name" target="_blank" :title="site.comment"></a><span v-text="site.comment!=null?site.comment:'暂未添加描述'">测试</span></li>
                    <li class="site"><div>+</div></li>
                </ul>
                <hr class="line-hr">
            </li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			navItems:[],
			temp:[],
			navSites:[],
			tagFlag: [-1,''],
			loading: true
		}
	},
	methods: {
		getAll(){
			let that = this
			this.axios.get('nav/getAllSite').then(function(results){
				that.navItems = results.data
				that.temp = JSON.parse(JSON.stringify(that.navItems))
				that.loading = false
			},function(err){
				console.log(err);
			})
		},
		tags(tid,e){
			let typeId = tid
			let tag = e.target.innerHTML
			let that = this
			tag=='全部'?that.tagFlag[1] = '':that.tagFlag[1] = tag
			tag=='全部'?that.tagFlag[0] = -1:that.tagFlag[0] = typeId
			let sites =new Array()
			that.navItems = JSON.parse(JSON.stringify(that.temp))
			if (tag!='全部'){
				sites = that.temp[typeId].sitesItems
				let i = 0
				for (let index in sites){
					if (sites[index].tags.indexOf(tag)!=-1){
						that.navItems[typeId].sitesItems[i]=sites[index]
						i++
						}
				}
				that.navItems[typeId].sitesItems = that.navItems[typeId].sitesItems.splice(0,i)
			}
		},
	},
	created() {
		this.getAll()
	}
}
</script>

<style scoped>
.guide-content{
	min-height: 5em;
}
.navItem{
    width: 100%;
    height: auto;
    margin: 3em 0;
    list-style: none;
    /*border-bottom: 1px solid #2658a1;*/
}
.type{
	position: relative;
    color: #ffffff;
    font-size: 1.7em;
    margin-left: 1.5em;
}
.type::before{
	content: '';
	position: absolute;
	left: -1.1em;
	top: .15em;
	width: .6em;
	height: .6em;
	border: 3px solid #29BDFF;
	border-radius: 50%;
}
#ul_nav{
    width: 70%;
	margin: 5em auto;
	padding: 1em 0;
	background-color: rgba(0,0,0,.3);
	border-radius: 2em;
	backdrop-filter: saturate(2) blur(10px);
}
.navItem div ul{
    padding: 0;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 1em;
}
.navItem>div,.navItem>ul{
	text-align: start;
	margin-top: 1em;
}
.navItem div ul:first-child{
    border-bottom: 1px solid rgba(255,255,255,.1);
}
.tag_icon{
    vertical-align: unset;
}
.tag_icon img{
    width: 1.1em;
    vertical-align: middle;
}
.tag{
    color: #a0e5e3;
    margin: 0 .2em;
    display: inline-block;
    list-style: none;
    cursor: pointer;
    transition: all ease-in-out  .3s;
}
.tag:hover{
    color: white;
}
.tagShow{
    color: white;
    border-bottom: 1px solid #29bdff;
}
.tagAll{
    border-bottom: 1px solid #2658a1;
    animation: hidden_tag_line 3s ease-in-out forwards;
}
@keyframes hidden_tag_line {
    from{
        border-bottom: 1px solid #2658a1;
    }
    to{
        border-bottom: 1px solid rgba(255,255,255,.1);
    }
}
.site{
    width: 23.5%;
    height: auto;
    font-size: 1.1em;
    text-align: center;
    margin: .3em;
    border-radius: .5em;
    transition: all ease-in-out .3s;
    display: inline-block;
}
.site a{
	text-decoration: none;
	color: #bdf8ff;
}
.site:hover{
    background-color: #29bcfe;
}
.site:hover a,.site:hover span{
    color: white;
}
.site span{
    font-size: .8em;
    color: #cacaca;
    display: block;
}
.line-hr{
    width: 65%;
    margin: 2em auto;
	border: 1px solid #87b1bd;
}
.darkApp .tag:hover{
	color: papayawhip;
}
.darkApp .tagShow{
	color: honeydew;
}
@media screen and (max-width: 1300px){
    ul{
        padding: 0;
    }
}
@media screen and (max-width: 1000px){
    .site{
        width: 31%;
    }
}
@media screen and (max-width: 770px){
	#ul_nav{
		width: 100%;
	}
    .site{
		width: 45%;
        font-size: 1em;
    }
    .site span{
        font-size: .6em;
    }
    .type{
        margin-left: 0;
        text-align: center;
        display: block;
        font-size: 1.7em !important;
    }
    .navItem div ul{
        margin-left: 1.5em;
    }
}
</style>
